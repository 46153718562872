<template>
    <div class="main">
        <!-- 导航 -->
        <page-head :title="`${name || ''}视频短片`"/>
        <div class="content" v-if="videoData.length > 0 && !loading">
            <div class="playBox">
                <video class="video" :src="currentVideo.url" controls autoplay></video>
                <div class="shadow"></div>
                <span class="videoLabel">{{currentVideo.label}}</span>
            </div>
            <div class="listBox">
                <div class="list">
                    <div v-for="(item, index) in videoData" :key="item.id" class="item"
                         :class="{ active: item.id === currentVideo.id }"
                         @click="details(item)">
                        <!-- 序号 -->
                        <index-number
                            class="number"
                            spanColor="rgba(255, 255, 255, 0.3)"
                            iColor="rgba(255, 255, 255, 0.8)"
                        >
                            <template v-slot:number>
                                <span style="color: #602B89;">{{ index + 1 }}</span>
                            </template>
                        </index-number>
                        <!-- 封面 -->
                        <div class="imgBox">
                            <div class="text">
                                <p>当前播放</p>
                                <div class="spinner">
                                    <span class="rect rect1"></span>
                                    <span class="rect rect2"></span>
                                    <span class="rect rect3"></span>
                                </div>
                            </div>
                            <i class="mask"></i>
                            <img class="img"
                                 :src="item.snapshot ? item.snapshot : require('@i/common/exerciseBg.png')"
                                 alt="">
                        </div>

                        <!-- 视频名 -->
                        <div class="item_box">
                            <p class="item_name">
                                {{ item.label }}
                            </p>
                            <p class="item_date akrobatRegular">
                                {{ $moment(new Date(item.createTime)).format('YYYY/MM/DD HH:mm:ss') }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 翻页 -->
                <pagination-com
                    v-if="videoData.length > 0"
                    class="pagination"
                    :current-page="query.page"
                    :total-page="totalPage"
                    @changePage="changePage"
                />
            </div>
        </div>
        <no-data v-else />
    </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { defineComponent, onMounted, getCurrentInstance, toRefs, reactive } from 'vue';
export default defineComponent({
    setup() {
        const router = useRouter()
        const {proxy} = getCurrentInstance();
        const {playerBag} = proxy.$Config.UI_CONFIG
        const route = useRoute();
        const {id, name, pageType} = route.query; // type video/player 主视频的短片/人员的短片
        const state = reactive({
            playerBag,
            name,
            query: {
                videoId: id, // 视频id
                paging: true,
                pageSize: 6,
                page: 1,
            },
            videoData: [],
            totalPage: '',
            loading: true,
            currentVideo: ''
        });

        const getData = async (setCurrent = true) => {
            state.loading = true;
            const resFn = async () => {
                if (pageType === 'video') {
                    return await proxy.$server.getVideoClipsList(state.query);
                } else if (pageType === 'player') {
                    const params = {
                        playerId: id,
                        teensCampId: '',
                        teamId: ''
                    };
                    return await proxy.$server.getPlayerVideo(params);
                }
            }
            const { code, data } = await resFn()
            state.loading = false;
            if (code === 200) {
                state.videoData = data.list;
                state.totalPage = data.pages;
                if (state.videoData.length && setCurrent) {
                    state.currentVideo = state.videoData[0];
                }
            }
        }

        const changePage = (page) => {
            state.query.page = page
            getData(false)
        }

        const details = (row) => {
            state.currentVideo = row;
        }
                
        onMounted(() => {
            getData()
        })
        
        return {
            changePage,
            details,
            ...toRefs(state),
        }
    },
})
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    position: relative;
    
    .content {
        position: relative;
        margin: 50px 80px 0;
        box-sizing: border-box;
        height: calc(100% - 112px);
        display: flex;

        .playBox {
            width: 1224px;
            height: 738px;
            border-radius: 8px;
            position: relative;
            background-color: #000000;
            display: flex;
            justify-content: center;

            video {
                width: 100%;
                border-radius: 8px;
            }

            .shadow {
                position: absolute;
                width: 1200px;
                height: 70px;
                left: 30px;
                bottom: -80px;

                background: linear-gradient(180deg, #140F22 32%, rgba(20, 15, 34, 0) 100%);
                opacity: 0.5;
                filter: blur(30px);
                backdrop-filter: blur(22px);
                /* Note: backdrop-filter has minimal browser support */

                border-radius: 112px;
                z-index: 0;
            }

            .videoLabel {
                display: inline-block;
                border-radius: 61px;
                padding: 0 20px;
                background: rgba(80, 80, 80, 0.3);
                border: 1px solid rgba(255, 255, 255, 0.2);
                position: absolute;
                height: 51px;
                line-height: 51px;
                left: 12px;
                top: 12px;
                box-sizing: border-box;
                color: rgba(255, 255, 255, 0.8);
                font-size: 22px;
            }
        }

        .listBox {
            width: calc(100% - 1224px);
        }

        .list {
            display: grid;
            grid-template-columns: repeat(2, 240px);
            grid-template-rows: max-content;
            justify-content: space-between;
            padding-left: 32px;
            height: 760px;
            box-sizing: border-box;

            .item {
                position: relative;
                width: 100%;
                height: 230px;
                cursor: pointer;
                overflow: hidden;
                background-color: rgba(0, 0, 0, .5);
                border-radius: 8px;
                margin-bottom: 24px;

                &.active {
                    background-color: #C28F31;
                    .text {
                        display: flex !important;
                    }
                    .mask {
                        display: block !important;
                    }
                }

                .number {
                    position: absolute;
                    top: 13px;
                    left: 13px;
                }

                .imgBox {
                    width: 100%;
                    height: 134px;
                    overflow: hidden;
                    position: relative;

                    .img {
                        width: 100%;
                    }

                    .text {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 9;
                        display: none;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        p {
                            color: #ffc85e;
                        }
                        .spinner {
                            display: flex;
                            align-items: flex-end;
                            margin-left: 10px;
                            .rect {
                                width: 2px;
                                margin-right: 6px;
                                background-color: #ffc85e;
                                border-radius: 2px;
                                animation: stretch 0.8s infinite ease-in-out;
                                &.rect1 {
                                    animation-delay: -1s;
                                }
                                &.rect2 {
                                    animation-delay: -0.4s;
                                }
                                &.rect3 {
                                    animation-delay: -0.8s;
                                }
                            }
                        }
                    }
                    .mask {
                        display: none;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 8;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.6);
                    }
                }


                .item_box {
                    color: #fff;
                    font-size: 22px;
                    padding-left: 8px;
                    padding-right: 8px;
                    box-sizing: border-box;

                    .item_name {
                        word-break: break-all;
                        width: 100%;
                        min-height: 31px;
                        line-height: 31px;
                        opacity: 0.8;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin-top: 12px;
                        margin-bottom: 8px;
                    }

                    .item_date {
                        opacity: 0.6;
                    }
                }
            }
        }

        .pagination {
            position: absolute;
            right: 65px;
            bottom: 82px;
        }
    }
}

@keyframes stretch {
    0%,
    100% {
        height: 0;
    }
    50% {
        height: 18px;
    }
}
</style>